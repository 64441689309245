<template>
  <div
    class="code-bg"
    style="width:100%;height:100vh"
  >
    <div >
      <!-- <ve-map
        ref="emap"
        :data="chartData"
        height="800px"
        :legend-visible="false"
        :settings="chartSettings"
        :events="chartEvents"
        :visual-map="chartVisualMap"
        :tooltip="tooltip"
      ></ve-map>-->
      <el-row :gutter="20">
        <el-col :span="18">
          <div style="border: 2px solid rgba(0,135,255,.6);">
            <ve-map
              height="90vh"
              :data="chartData"
              :legend-visible="false"
              :settings="chartSettings"
              :visual-map="chartVisualMap"
              :events="chartEvents"
            ></ve-map>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="height:90vh;border: 2px solid rgba(0,135,255,.6)">
            <ve-bar
              height="90vh"
              width="98%"
              :data="barChartData"
              :settings="barChartSettings"
              :extend="barChartExtend"
              :colors="barColors"
              :textStyle="barTextStyle"
            ></ve-bar>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top:20px;">
      <el-row :gutter="15">
        <el-col :span="2">
          <el-button v-if="provinceFlag" type="primary" @click="reset()">返回上级</el-button>
        </el-col>
        <el-col :span="4">
          <el-input v-model="query.gtin" placeholder="请输入商品编号">
            <template slot="prepend">商品编号</template>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="query.batchNumber" placeholder="请输入批号">
            <template slot="prepend">批号</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            unlink-panels
            value-format="yyyy-MM-dd"
            clearable
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button style="margin-left:15px;" type="primary" @click="submit()">统计</el-button>
          <el-button style="margin-left:10px;" @click="reset1()">重置</el-button>
        </el-col>
        <el-col :span="4">
          <span style="color:#fff">数据获取时间:{{countTime}}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import mapBg from "@/assets/image/mapbg.png";
import provinces from "./provinces.json";
import transformation from "./transformation.json";
import "echarts/lib/component/visualMap";
import api from "../../../util/extra-api";
import {formatTime} from "../../../util/util";

export default {
  components: {},
  data() {
    this.chartVisualMap = {
      min: 0,
      max: 1,
      show: true,
      left: "left",
      top: "bottom",
      text: ["High", "Low"],
      seriesIndex: [0],
      textStyle: {
        color: "#fff"
      },
      inRange: {
        color: ["#FFC809", "#F46E36"]
      },
      calculable: true,
    };
    this.chartEvents = {
      mapselectchanged: v => {
        const provinceName = this.provincesData[v.batch[0].name];
        if (provinceName) {
          this.chartSettings.position = `province/${provinceName}`;
          this.getDataCity(v.batch[0].name);
          this.provinceFlag = true;
        }
      }
    };
    this.barChartSettings = {


      labelMap: {
        total: "统计值"
      },
      itemStyle: {
        normal: {
          label: {
            show: true, //开启显示
            position: "right", //在上方显示
            textStyle: {
              //数值样式
              color: "#fff",
              fontSize: 16
            }
          }
        }
      }
    };
    // this.barLegend = {
    //   textStyle:{
    //     color:'#fff'
    //   }
    // }
    this.barTextStyle = {
      color: "#fff",
      fontSize: 13
    };
    this.barChartExtend = {
      series: {
        type: "bar",
        barMaxWidth: "30"
      },
      legend: {
        textStyle: {
          color: "#fff"
        }
      },
      xAxis: {
        minInterval: 1
      }
    };
    this.barColors = ["#04B9FF"];
    // this.tooltip = {
    //   trigger: "item",
    //   formatter(v) {
    //     console.log(v)
    //     if (v.data.value == undefined) {
    //       return "";
    //     } else {
    //       let str = `${v.name} <br/>${v.marker} ${v.seriesName} : ${v.data.value}`;
    //       return str;
    //     }
    //   }
    // };
    return {
      cityName: "",
      provinceName: '',
      provincesData: {},
      transformation,
      chartData: {
        columns: ["位置", "扫码次数"],
        rows: []
      },
      barChartData: {
        columns: ["位置", "扫码次数"],
        rows: []
      },
      query: {
        gtin: "",
        batchNumber: '',
        startTime: "",
        endTime: "",
        province: ''
      },
      mapBg,
      timeRange: "",
      provinceFlag: false,
      countTime: "",
      chartSettings: {
        position: "china",
        selectedMode: "single",
        labelMap: {
          total: "扫码次数"
        },
        label: {
          normal: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 13
            }
          },
          emphasis: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 13
            }
          }
        },
        itemStyle: {
          normal: {
            areaColor: "transparent",
            borderColor: "rgba(147, 235, 248, 1)",
            borderWidth: 1,
            shadowColor: "rgba(128, 217, 248, 1)",
            shadowOffsetX: -2,
            shadowOffsetY: 2,
            shadowBlur: 10
          },
          emphasis: {
            areaColor: "#04B9FF",
            shadowBlur: 10,
            shadowColor: "#fff"
          }
        }
        // normal: {
        //   borderColor: "rgba(0, 0, 0, 0.2)"
        // },
        // emphasis: {
        //   areaColor: null,
        //   shadowOffsetX: 0,
        //   shadowOffsetY: 0,
        //   shadowBlur: 10,
        //   shadowColor: "rgba(0, 0, 0, 0.5)"
        // }
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    // areaClick(data) {
    //   if (data in this.provincesData) {
    //     this.$nextTick(_ => {
    //       this.chartSettings.position = "province/" + this.provincesData[data];
    //       this.chartData = {
    //         columns: ["位置", "people"],
    //         rows: [{ 位置: "海淀区", people: 4123 }]
    //       };
    //     });
    //   }
    // }
    getData(val) {
      if(val === 2){
        this.query.province = this.provinceName;

      }else{
        this.query.province = ''
      }
      api.get('/v1/pc/scanCodeRecord/chart1',this.query).then(response => {
            this.chartData.columns = ["region", "total"];
            if(val === 1){
              this.chartData.rows = response.data.result.map(m =>{
                return {
                  region: transformation[transformation.findIndex(f => f.name2 === m.region)].name1,
                  total: m.total
                }
              });
            }else{
                let res = response.data.result;
                // res.forEach(r => {
                //   r.city += "市"
                // });
                this.chartData.columns = ["region", "total"];
                this.chartData.rows = res;
            }
            this.barChartData.columns = ["region", "total"];
            this.barChartData.rows = response.data.result;
          // case 2:
          //   let res = response.data.result;
          //   // res.forEach(r => {
          //   //   r.city += "市"
          //   // });
          //   this.chartData.columns = ["city", "total"];
          //   this.chartData.rows = res;
          //   break;

        if (response.data.max) {
          this.chartVisualMap.max = response.data.max;
        } else {
          this.chartVisualMap.max = 1;
        }
        this.countTime = formatTime(new Date(), 'yyyy-MM-dd HH:mm:ss');
      });
    },
    getDataCity(provinceName) {
      let province = transformation[transformation.findIndex(f => f.name1 === provinceName)].name2
      this.provinceName = province
      let data={province:province}
      if(this.query.batchNumber){
        data.batchNumber=this.query.batchNumber
      }
      if(this.query.gtin){
        data.gtin=this.query.gtin
      }
      if(this.query.startTime){
        data.startTime=this.query.startTime
      }
      if(this.query.endTime){
        data.endTime=this.query.endTime
      }
      api.get('/v1/pc/scanCodeRecord/chart1',data).then(response => {
            // this.chartData.columns = ["region", "total"];
            // this.chartData.rows = response.data.result.map(m =>{
            //   return {
            //     region: transformation[transformation.findIndex(f => f.name2 === m.region)].name1,
            //     total: m.total
            //   }
            // });
            // this.barChartData.columns = ["region", "total"];
            // this.barChartData.rows = response.data.result;
          // case 2:
            let res = response.data.result;
            // res.forEach(r => {
            //   r.city += "市"
            // });
            this.chartData.columns = ["region", "total"];
            this.chartData.rows = res;
          //   break;

        if (response.data.max) {
          this.chartVisualMap.max = response.data.max;
        } else {
          this.chartVisualMap.max = 1;
        }
        this.countTime = formatTime(new Date(), 'yyyy-MM-dd HH:mm:ss');
      });
    },
    reset() {
      this.chartSettings.position = "china";
      this.getData(1);
      this.provinceFlag = false;
    },
    reset1() {
      this.query = {
        gtin: "",
        batchNumber: "",
        startTime: "",
        endTime: "",
        province: ''
      };
      this.timeRange = "";
      this.chartSettings.position = "china";
      this.getData(1);
      this.provinceFlag = false;
    },
    submit() {
      if (this.timeRange) {
        this.query.startTime = this.timeRange[0];
        this.query.endTime = this.timeRange[1];
      }
      this.getData(!this.provinceFlag ? 1 : 2);
    }
  },
  created() {},
  mounted() {
    this.provincesData = provinces;
    this.getData(1);
  }
};
</script>
<style>
  .el-row{
    margin: 0!important;
  }
  .code-bg{
    transform-origin: 0px 0px 0px;
    background-image: url("../../../assets/image/mapbg.png");
    background-size: cover;
    background-position: 50% 0;
    background-color: rgb(0, 0, 0);
    min-width: auto;
    width: 100%;
    min-height: auto;
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
  }
</style>
